<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    title="重置密码 "
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import userService from '@/api/service/user.js';
  import md5 from 'js-md5';

  export default {
    name: 'resetPsdDialog',
    data() {
      return {
        id: null,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '密码',
              prop: 'password',
              rules: [this.$formRules.required('请输入密码')]
            },
            attrs: {
              type: 'password',
              showPassword: true
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        this.id = id;
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            const params = {
              id: this.id,
              password: md5(this.formData.password)
            };
            return userService.editPasswordByAdmin(params);
          })
          .then(() => {
            done();
            this.$emit('confirm');
            this.close();
            if (this.id === this.$store.getters.userInfo.id) {
              setTimeout(() => {
                this.$store.dispatch('setToken', {});
                window.location.replace('/login');
              }, 1000);
            }
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.formData = {};
        this.id = null;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
